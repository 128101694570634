// extracted by mini-css-extract-plugin
export var WhyBrass = "whybrass-module--WhyBrass--S2-1f";
export var WhyBrass_container = "whybrass-module--WhyBrass_container--IGfLt";
export var WhyBrass_container_fullWidthImg = "whybrass-module--WhyBrass_container_fullWidthImg--vuqgF";
export var WhyBrass_container_header_preTtl = "whybrass-module--WhyBrass_container_header_preTtl--bPQV8";
export var WhyBrass_container_header_ttl = "whybrass-module--WhyBrass_container_header_ttl--gK58g";
export var container = "whybrass-module--container--hWeD7";
export var gatsbyImageWrapperConstrained = "whybrass-module--gatsby-image-wrapper-constrained--EZtGV";
export var gridSpan1010 = "whybrass-module--grid-span-10-10--Pdk+n";
export var gridSpan1011 = "whybrass-module--grid-span-10-11--7dAOj";
export var gridSpan102 = "whybrass-module--grid-span-10-2--bKjwe";
export var gridSpan103 = "whybrass-module--grid-span-10-3--gtuTq";
export var gridSpan104 = "whybrass-module--grid-span-10-4--ysWu+";
export var gridSpan105 = "whybrass-module--grid-span-10-5--BihQQ";
export var gridSpan106 = "whybrass-module--grid-span-10-6--68VUs";
export var gridSpan107 = "whybrass-module--grid-span-10-7--cfzaV";
export var gridSpan108 = "whybrass-module--grid-span-10-8--Y4BeK";
export var gridSpan109 = "whybrass-module--grid-span-10-9--LUOG+";
export var gridSpan110 = "whybrass-module--grid-span-1-10--YxNdc";
export var gridSpan111 = "whybrass-module--grid-span-1-11--X3wVG";
export var gridSpan1110 = "whybrass-module--grid-span-11-10--R1fB-";
export var gridSpan1111 = "whybrass-module--grid-span-11-11--2qc9F";
export var gridSpan112 = "whybrass-module--grid-span-11-2--4W9k5";
export var gridSpan113 = "whybrass-module--grid-span-11-3--vxR6O";
export var gridSpan114 = "whybrass-module--grid-span-11-4--J7Zy4";
export var gridSpan115 = "whybrass-module--grid-span-11-5--dAUfH";
export var gridSpan116 = "whybrass-module--grid-span-11-6--7d9e1";
export var gridSpan117 = "whybrass-module--grid-span-11-7--wCBs8";
export var gridSpan118 = "whybrass-module--grid-span-11-8--khzU1";
export var gridSpan119 = "whybrass-module--grid-span-11-9--Oge7B";
export var gridSpan12 = "whybrass-module--grid-span-1-2--EU0oi";
export var gridSpan1210 = "whybrass-module--grid-span-12-10--olBBO";
export var gridSpan1211 = "whybrass-module--grid-span-12-11--a4zLJ";
export var gridSpan122 = "whybrass-module--grid-span-12-2--T-SaM";
export var gridSpan123 = "whybrass-module--grid-span-12-3--VljNM";
export var gridSpan124 = "whybrass-module--grid-span-12-4--K7QGe";
export var gridSpan125 = "whybrass-module--grid-span-12-5--wCEJH";
export var gridSpan126 = "whybrass-module--grid-span-12-6--zv9px";
export var gridSpan127 = "whybrass-module--grid-span-12-7--YFPqC";
export var gridSpan128 = "whybrass-module--grid-span-12-8--Qqw47";
export var gridSpan129 = "whybrass-module--grid-span-12-9--DK6fD";
export var gridSpan13 = "whybrass-module--grid-span-1-3--jPpPB";
export var gridSpan14 = "whybrass-module--grid-span-1-4--HIHEf";
export var gridSpan15 = "whybrass-module--grid-span-1-5--4mNeP";
export var gridSpan16 = "whybrass-module--grid-span-1-6--6qV9Q";
export var gridSpan17 = "whybrass-module--grid-span-1-7--Kuhtp";
export var gridSpan18 = "whybrass-module--grid-span-1-8--MCctc";
export var gridSpan19 = "whybrass-module--grid-span-1-9--5QBRU";
export var gridSpan210 = "whybrass-module--grid-span-2-10--CbvgY";
export var gridSpan211 = "whybrass-module--grid-span-2-11--5VpM+";
export var gridSpan22 = "whybrass-module--grid-span-2-2--4Phdq";
export var gridSpan23 = "whybrass-module--grid-span-2-3--hL89S";
export var gridSpan24 = "whybrass-module--grid-span-2-4--M9L-Q";
export var gridSpan25 = "whybrass-module--grid-span-2-5--HE+XH";
export var gridSpan26 = "whybrass-module--grid-span-2-6--nIZCU";
export var gridSpan27 = "whybrass-module--grid-span-2-7--bqtbp";
export var gridSpan28 = "whybrass-module--grid-span-2-8--49xNA";
export var gridSpan29 = "whybrass-module--grid-span-2-9--KPiDn";
export var gridSpan310 = "whybrass-module--grid-span-3-10--+UXh6";
export var gridSpan311 = "whybrass-module--grid-span-3-11--GNEjI";
export var gridSpan32 = "whybrass-module--grid-span-3-2--YS9NG";
export var gridSpan33 = "whybrass-module--grid-span-3-3--nPIf6";
export var gridSpan34 = "whybrass-module--grid-span-3-4--XFX9X";
export var gridSpan35 = "whybrass-module--grid-span-3-5--hVEPF";
export var gridSpan36 = "whybrass-module--grid-span-3-6--pxsuo";
export var gridSpan37 = "whybrass-module--grid-span-3-7--6pnYL";
export var gridSpan38 = "whybrass-module--grid-span-3-8--rK+NB";
export var gridSpan39 = "whybrass-module--grid-span-3-9--NypHs";
export var gridSpan410 = "whybrass-module--grid-span-4-10--yqrgr";
export var gridSpan411 = "whybrass-module--grid-span-4-11--jfjoh";
export var gridSpan42 = "whybrass-module--grid-span-4-2--cwwM6";
export var gridSpan43 = "whybrass-module--grid-span-4-3--ZrSJn";
export var gridSpan44 = "whybrass-module--grid-span-4-4--adnE+";
export var gridSpan45 = "whybrass-module--grid-span-4-5--hUzwZ";
export var gridSpan46 = "whybrass-module--grid-span-4-6--HSECM";
export var gridSpan47 = "whybrass-module--grid-span-4-7--c+P9+";
export var gridSpan48 = "whybrass-module--grid-span-4-8--F-IOF";
export var gridSpan49 = "whybrass-module--grid-span-4-9--icwER";
export var gridSpan510 = "whybrass-module--grid-span-5-10--KD-Hg";
export var gridSpan511 = "whybrass-module--grid-span-5-11--4+D9s";
export var gridSpan52 = "whybrass-module--grid-span-5-2--qQWaS";
export var gridSpan53 = "whybrass-module--grid-span-5-3--2lpbe";
export var gridSpan54 = "whybrass-module--grid-span-5-4--EWIej";
export var gridSpan55 = "whybrass-module--grid-span-5-5--vcT1H";
export var gridSpan56 = "whybrass-module--grid-span-5-6--zJKLd";
export var gridSpan57 = "whybrass-module--grid-span-5-7--JodRo";
export var gridSpan58 = "whybrass-module--grid-span-5-8--zH0Og";
export var gridSpan59 = "whybrass-module--grid-span-5-9--MRdYO";
export var gridSpan610 = "whybrass-module--grid-span-6-10--SHXJN";
export var gridSpan611 = "whybrass-module--grid-span-6-11--zGQDQ";
export var gridSpan62 = "whybrass-module--grid-span-6-2--GhB5Z";
export var gridSpan63 = "whybrass-module--grid-span-6-3--MsEBB";
export var gridSpan64 = "whybrass-module--grid-span-6-4--Txole";
export var gridSpan65 = "whybrass-module--grid-span-6-5--tsqf4";
export var gridSpan66 = "whybrass-module--grid-span-6-6--MFfa7";
export var gridSpan67 = "whybrass-module--grid-span-6-7--iIjw2";
export var gridSpan68 = "whybrass-module--grid-span-6-8--UletZ";
export var gridSpan69 = "whybrass-module--grid-span-6-9--nzUDQ";
export var gridSpan710 = "whybrass-module--grid-span-7-10--N3Pr-";
export var gridSpan711 = "whybrass-module--grid-span-7-11--NMzA1";
export var gridSpan72 = "whybrass-module--grid-span-7-2--HEKnJ";
export var gridSpan73 = "whybrass-module--grid-span-7-3--1G3ua";
export var gridSpan74 = "whybrass-module--grid-span-7-4--SqITY";
export var gridSpan75 = "whybrass-module--grid-span-7-5--nLd40";
export var gridSpan76 = "whybrass-module--grid-span-7-6--gtmVe";
export var gridSpan77 = "whybrass-module--grid-span-7-7--QJZ+C";
export var gridSpan78 = "whybrass-module--grid-span-7-8--YEG5b";
export var gridSpan79 = "whybrass-module--grid-span-7-9--b+lYh";
export var gridSpan810 = "whybrass-module--grid-span-8-10--2b3KU";
export var gridSpan811 = "whybrass-module--grid-span-8-11--zqxhW";
export var gridSpan82 = "whybrass-module--grid-span-8-2--PAusM";
export var gridSpan83 = "whybrass-module--grid-span-8-3--MK459";
export var gridSpan84 = "whybrass-module--grid-span-8-4--LV3v7";
export var gridSpan85 = "whybrass-module--grid-span-8-5--vkvwg";
export var gridSpan86 = "whybrass-module--grid-span-8-6--ufApp";
export var gridSpan87 = "whybrass-module--grid-span-8-7--BI5bU";
export var gridSpan88 = "whybrass-module--grid-span-8-8--+-G3b";
export var gridSpan89 = "whybrass-module--grid-span-8-9--ro6xA";
export var gridSpan910 = "whybrass-module--grid-span-9-10--Tmjf7";
export var gridSpan911 = "whybrass-module--grid-span-9-11--cxJPR";
export var gridSpan92 = "whybrass-module--grid-span-9-2--Fs0ac";
export var gridSpan93 = "whybrass-module--grid-span-9-3--A15d6";
export var gridSpan94 = "whybrass-module--grid-span-9-4--5e35F";
export var gridSpan95 = "whybrass-module--grid-span-9-5--2dBO5";
export var gridSpan96 = "whybrass-module--grid-span-9-6--OqPlj";
export var gridSpan97 = "whybrass-module--grid-span-9-7--d5dcA";
export var gridSpan98 = "whybrass-module--grid-span-9-8--+t8uz";
export var gridSpan99 = "whybrass-module--grid-span-9-9--WrI9y";
export var textBreak = "whybrass-module--text-break--uhb4x";