import * as styles from "./ExternalResearch.module.scss";

import React from "react";
import { WHY_BRASS_DATA } from "data/pages";

const ExternalResearch: React.FC = () => {
  return (
    <section className={styles.ExternalResearch}>
      <div className={styles.ExternalResearch_container}>
        <section className={styles.ExternalResearch_container_keyFindings}>
          <h1 className={styles.ExternalResearch_container_keyFindings_ttl}>
            Key findings from external research
          </h1>
          <div className={styles.ExternalResearch_container_keyFindings_grid}>
            <div
              className={
                styles.ExternalResearch_container_keyFindings_grid_item
              }
            >
              <h1>99%</h1>
              <p>of all Nigerian businesses are MSMEs.</p>
            </div>
            <div
              className={
                styles.ExternalResearch_container_keyFindings_grid_item
              }
            >
              <h1>76%</h1>
              <p>They employ more than 76% of the workforce</p>
            </div>
            <div
              className={
                styles.ExternalResearch_container_keyFindings_grid_item
              }
            >
              <h1>50%</h1>
              <p>They have almost 50% contribution to the GDP</p>
            </div>
          </div>
        </section>
        <section className={styles.ExternalResearch_container_highlight}>
          <h1 className={styles.ExternalResearch_container_highlight_ttl}>
            Highlight of their top problems by survey
          </h1>

          <p>
            In emerging markets and developing economies, 55%-68% of formal SMEs
            are either unserved or underserved by financial institutions,
            leading to credit gap estimated to be US$5.1 trillion.
          </p>
          <br />
          <small className={styles.ExternalResearch_container_highlight_src}>
            Source: PwC's MSME Survey 2020: Building to Last
          </small>
        </section>
        <section className={styles.ExternalResearch_container_resources}>
          <h2 className={styles.ExternalResearch_container_resources_ttl}>
            Learn more from other relevant materials on SMEs in emerging markets
          </h2>
          <div className={styles.ExternalResearch_container_resources_links}>
            {WHY_BRASS_DATA.SME_RESOURCES.map(({ label, link }) => {
              return (
                <a
                  key={link}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.ExternalResearch_container_resources}
                >
                  {label}
                </a>
              );
            })}
          </div>
        </section>
      </div>
    </section>
  );
};

export default ExternalResearch;
