// extracted by mini-css-extract-plugin
export var ExternalResearch = "ExternalResearch-module--ExternalResearch--83hQz";
export var ExternalResearch_container = "ExternalResearch-module--ExternalResearch_container--rCH8U";
export var ExternalResearch_container_highlight = "ExternalResearch-module--ExternalResearch_container_highlight--z1uhp";
export var ExternalResearch_container_highlight_src = "ExternalResearch-module--ExternalResearch_container_highlight_src--qhWUM";
export var ExternalResearch_container_highlight_ttl = "ExternalResearch-module--ExternalResearch_container_highlight_ttl--QB-NW";
export var ExternalResearch_container_keyFindings = "ExternalResearch-module--ExternalResearch_container_keyFindings---flTu";
export var ExternalResearch_container_keyFindings_grid = "ExternalResearch-module--ExternalResearch_container_keyFindings_grid--1dcCb";
export var ExternalResearch_container_keyFindings_grid_item = "ExternalResearch-module--ExternalResearch_container_keyFindings_grid_item--0AJlW";
export var ExternalResearch_container_keyFindings_ttl = "ExternalResearch-module--ExternalResearch_container_keyFindings_ttl--1UfUf";
export var ExternalResearch_container_resources = "ExternalResearch-module--ExternalResearch_container_resources--QLzrJ";
export var ExternalResearch_container_resources_links = "ExternalResearch-module--ExternalResearch_container_resources_links--liv+Z";
export var ExternalResearch_container_resources_ttl = "ExternalResearch-module--ExternalResearch_container_resources_ttl--1fyEU";
export var container = "ExternalResearch-module--container--myTJg";
export var gatsbyImageWrapperConstrained = "ExternalResearch-module--gatsby-image-wrapper-constrained--11f5B";
export var gridSpan1010 = "ExternalResearch-module--grid-span-10-10--CaHxr";
export var gridSpan1011 = "ExternalResearch-module--grid-span-10-11--lVKpO";
export var gridSpan102 = "ExternalResearch-module--grid-span-10-2--+kpEx";
export var gridSpan103 = "ExternalResearch-module--grid-span-10-3--WMvcx";
export var gridSpan104 = "ExternalResearch-module--grid-span-10-4--Uevd6";
export var gridSpan105 = "ExternalResearch-module--grid-span-10-5--nrBu5";
export var gridSpan106 = "ExternalResearch-module--grid-span-10-6--bDD3s";
export var gridSpan107 = "ExternalResearch-module--grid-span-10-7--WNf48";
export var gridSpan108 = "ExternalResearch-module--grid-span-10-8--SViwO";
export var gridSpan109 = "ExternalResearch-module--grid-span-10-9--lf-Lb";
export var gridSpan110 = "ExternalResearch-module--grid-span-1-10--d1j4E";
export var gridSpan111 = "ExternalResearch-module--grid-span-1-11--vMEre";
export var gridSpan1110 = "ExternalResearch-module--grid-span-11-10--9HwX1";
export var gridSpan1111 = "ExternalResearch-module--grid-span-11-11--ei91j";
export var gridSpan112 = "ExternalResearch-module--grid-span-11-2--jlWSA";
export var gridSpan113 = "ExternalResearch-module--grid-span-11-3--IkxNQ";
export var gridSpan114 = "ExternalResearch-module--grid-span-11-4--omDNS";
export var gridSpan115 = "ExternalResearch-module--grid-span-11-5--KKMg7";
export var gridSpan116 = "ExternalResearch-module--grid-span-11-6--fiwiY";
export var gridSpan117 = "ExternalResearch-module--grid-span-11-7--NMwfx";
export var gridSpan118 = "ExternalResearch-module--grid-span-11-8--AVe5l";
export var gridSpan119 = "ExternalResearch-module--grid-span-11-9--0rUmc";
export var gridSpan12 = "ExternalResearch-module--grid-span-1-2--jaoVE";
export var gridSpan1210 = "ExternalResearch-module--grid-span-12-10--uP5zF";
export var gridSpan1211 = "ExternalResearch-module--grid-span-12-11--NQp0W";
export var gridSpan122 = "ExternalResearch-module--grid-span-12-2--uuZ5d";
export var gridSpan123 = "ExternalResearch-module--grid-span-12-3--wIgUE";
export var gridSpan124 = "ExternalResearch-module--grid-span-12-4--IxOln";
export var gridSpan125 = "ExternalResearch-module--grid-span-12-5--mF0YQ";
export var gridSpan126 = "ExternalResearch-module--grid-span-12-6--1hqGg";
export var gridSpan127 = "ExternalResearch-module--grid-span-12-7--HYTQD";
export var gridSpan128 = "ExternalResearch-module--grid-span-12-8--ySORg";
export var gridSpan129 = "ExternalResearch-module--grid-span-12-9--cI+PV";
export var gridSpan13 = "ExternalResearch-module--grid-span-1-3--QEPCW";
export var gridSpan14 = "ExternalResearch-module--grid-span-1-4--yiCCq";
export var gridSpan15 = "ExternalResearch-module--grid-span-1-5--wtQwV";
export var gridSpan16 = "ExternalResearch-module--grid-span-1-6--57iEy";
export var gridSpan17 = "ExternalResearch-module--grid-span-1-7--oDbiL";
export var gridSpan18 = "ExternalResearch-module--grid-span-1-8--Q7X-S";
export var gridSpan19 = "ExternalResearch-module--grid-span-1-9--hpDQm";
export var gridSpan210 = "ExternalResearch-module--grid-span-2-10--MOdnj";
export var gridSpan211 = "ExternalResearch-module--grid-span-2-11--nJIKm";
export var gridSpan22 = "ExternalResearch-module--grid-span-2-2--KbSd1";
export var gridSpan23 = "ExternalResearch-module--grid-span-2-3--lXORI";
export var gridSpan24 = "ExternalResearch-module--grid-span-2-4--E5POL";
export var gridSpan25 = "ExternalResearch-module--grid-span-2-5--TcyLe";
export var gridSpan26 = "ExternalResearch-module--grid-span-2-6--UnEg5";
export var gridSpan27 = "ExternalResearch-module--grid-span-2-7--XdLj8";
export var gridSpan28 = "ExternalResearch-module--grid-span-2-8--VdzTE";
export var gridSpan29 = "ExternalResearch-module--grid-span-2-9--n-+CV";
export var gridSpan310 = "ExternalResearch-module--grid-span-3-10--7ruqv";
export var gridSpan311 = "ExternalResearch-module--grid-span-3-11--1-iVJ";
export var gridSpan32 = "ExternalResearch-module--grid-span-3-2--qWFkw";
export var gridSpan33 = "ExternalResearch-module--grid-span-3-3--hEbKD";
export var gridSpan34 = "ExternalResearch-module--grid-span-3-4--coLDw";
export var gridSpan35 = "ExternalResearch-module--grid-span-3-5--xHJbP";
export var gridSpan36 = "ExternalResearch-module--grid-span-3-6--uVPfL";
export var gridSpan37 = "ExternalResearch-module--grid-span-3-7--MxVqp";
export var gridSpan38 = "ExternalResearch-module--grid-span-3-8--SNJMF";
export var gridSpan39 = "ExternalResearch-module--grid-span-3-9--Mu953";
export var gridSpan410 = "ExternalResearch-module--grid-span-4-10--lU71K";
export var gridSpan411 = "ExternalResearch-module--grid-span-4-11--j+SJw";
export var gridSpan42 = "ExternalResearch-module--grid-span-4-2--T-Cwu";
export var gridSpan43 = "ExternalResearch-module--grid-span-4-3--TRbgn";
export var gridSpan44 = "ExternalResearch-module--grid-span-4-4--z9gql";
export var gridSpan45 = "ExternalResearch-module--grid-span-4-5--y4kP5";
export var gridSpan46 = "ExternalResearch-module--grid-span-4-6--KVcuM";
export var gridSpan47 = "ExternalResearch-module--grid-span-4-7--T5NgK";
export var gridSpan48 = "ExternalResearch-module--grid-span-4-8--78XkJ";
export var gridSpan49 = "ExternalResearch-module--grid-span-4-9--yIU3K";
export var gridSpan510 = "ExternalResearch-module--grid-span-5-10--019kS";
export var gridSpan511 = "ExternalResearch-module--grid-span-5-11--lH5KO";
export var gridSpan52 = "ExternalResearch-module--grid-span-5-2--G49cd";
export var gridSpan53 = "ExternalResearch-module--grid-span-5-3--dSvZ7";
export var gridSpan54 = "ExternalResearch-module--grid-span-5-4--pxhZU";
export var gridSpan55 = "ExternalResearch-module--grid-span-5-5--qiWU4";
export var gridSpan56 = "ExternalResearch-module--grid-span-5-6--xm+Ju";
export var gridSpan57 = "ExternalResearch-module--grid-span-5-7--ommac";
export var gridSpan58 = "ExternalResearch-module--grid-span-5-8--XV1Q2";
export var gridSpan59 = "ExternalResearch-module--grid-span-5-9--Nm0TN";
export var gridSpan610 = "ExternalResearch-module--grid-span-6-10--oX2Ks";
export var gridSpan611 = "ExternalResearch-module--grid-span-6-11--xA7fq";
export var gridSpan62 = "ExternalResearch-module--grid-span-6-2--70gMb";
export var gridSpan63 = "ExternalResearch-module--grid-span-6-3--evL0h";
export var gridSpan64 = "ExternalResearch-module--grid-span-6-4--50CKD";
export var gridSpan65 = "ExternalResearch-module--grid-span-6-5--MaN8W";
export var gridSpan66 = "ExternalResearch-module--grid-span-6-6--FsmSm";
export var gridSpan67 = "ExternalResearch-module--grid-span-6-7--fI7XR";
export var gridSpan68 = "ExternalResearch-module--grid-span-6-8--RfHaz";
export var gridSpan69 = "ExternalResearch-module--grid-span-6-9--CT6TX";
export var gridSpan710 = "ExternalResearch-module--grid-span-7-10--BDDGv";
export var gridSpan711 = "ExternalResearch-module--grid-span-7-11--3u6IF";
export var gridSpan72 = "ExternalResearch-module--grid-span-7-2--wYjg+";
export var gridSpan73 = "ExternalResearch-module--grid-span-7-3--ThbOD";
export var gridSpan74 = "ExternalResearch-module--grid-span-7-4--L940A";
export var gridSpan75 = "ExternalResearch-module--grid-span-7-5--vs9YK";
export var gridSpan76 = "ExternalResearch-module--grid-span-7-6--7nxvm";
export var gridSpan77 = "ExternalResearch-module--grid-span-7-7--cpOd-";
export var gridSpan78 = "ExternalResearch-module--grid-span-7-8--qHJ6j";
export var gridSpan79 = "ExternalResearch-module--grid-span-7-9--BhN2z";
export var gridSpan810 = "ExternalResearch-module--grid-span-8-10--gtvLK";
export var gridSpan811 = "ExternalResearch-module--grid-span-8-11--Awaoa";
export var gridSpan82 = "ExternalResearch-module--grid-span-8-2--k2QfA";
export var gridSpan83 = "ExternalResearch-module--grid-span-8-3--ronXJ";
export var gridSpan84 = "ExternalResearch-module--grid-span-8-4--U5--B";
export var gridSpan85 = "ExternalResearch-module--grid-span-8-5--2ZaVn";
export var gridSpan86 = "ExternalResearch-module--grid-span-8-6--sexDg";
export var gridSpan87 = "ExternalResearch-module--grid-span-8-7--Ym+47";
export var gridSpan88 = "ExternalResearch-module--grid-span-8-8--LA-lj";
export var gridSpan89 = "ExternalResearch-module--grid-span-8-9--qr8KE";
export var gridSpan910 = "ExternalResearch-module--grid-span-9-10--BTk9C";
export var gridSpan911 = "ExternalResearch-module--grid-span-9-11--6mlQK";
export var gridSpan92 = "ExternalResearch-module--grid-span-9-2--gPFps";
export var gridSpan93 = "ExternalResearch-module--grid-span-9-3--9Zzpw";
export var gridSpan94 = "ExternalResearch-module--grid-span-9-4--ZENur";
export var gridSpan95 = "ExternalResearch-module--grid-span-9-5---Xvyx";
export var gridSpan96 = "ExternalResearch-module--grid-span-9-6--aOGiq";
export var gridSpan97 = "ExternalResearch-module--grid-span-9-7--GcAql";
export var gridSpan98 = "ExternalResearch-module--grid-span-9-8--hjffz";
export var gridSpan99 = "ExternalResearch-module--grid-span-9-9--qyVqk";
export var textBreak = "ExternalResearch-module--text-break--o2UhO";