import React from "react";
import { graphql, PageProps } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image";

import * as styles from "./whybrass.module.scss";

import pagesMeta from "data/pagesMeta";

import { CustomersGrid, ExternalResearch } from "components/why-brass";
import { GetStarted } from "components/design-system";
import SEO from "components/SEO";

const WhyBrass: React.FC<PageProps> = ({ data }) => {
  const {
    site: { siteMetadata },
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;

  const metaImage = getImage(
    pageData.customerData[2].img.childImageSharp.gatsbyImageData
  )?.images?.fallback?.src;

  return (
    <>
      <SEO
        {...pagesMeta.whyBrass}
        image={`${siteMetadata.siteUrl}${metaImage}`}
      />
      <div className={styles.WhyBrass}>
        <section className={styles.WhyBrass_container}>
          <header>
            <h6 className={styles.WhyBrass_container_header_preTtl}>
              OUR STORY
            </h6>
            <h1 className={styles.WhyBrass_container_header_ttl}>
              This is not about us
            </h1>
          </header>
          <p>
            It is about the millions of local businesses struggling to lay their
            foundation and grow. In 2020, we started Brass with the simple
            belief that businesses everywhere can have access to top-end money
            operations and cashflow services to ensure they can finance their
            business growth with cheaper and easy-to-access financing throughout
            their lifetime.
          </p>
        </section>
        <CustomersGrid items={pageData.customerData} />
        <section className={styles.WhyBrass_container}>
          <p>
            Many African businesses do not have the opportunity to access the
            tools and services they need to accelerate their growth and so we
            decided to build financial products that work in their favour. We
            have made some good progress since then. We created products that
            work to provide clarity into your business' finance and decisions.
            Our products today help thousands of businesses do better with their
            money operations, from accepting payments to taking care of supplier
            payments, we ensure that the process is efficient.{" "}
          </p>
          <p>
            This is just the beginning however. Our mission is to make economic
            prosperity more accessible to everyone. We know and understand that
            if we must succeed at providing this, there is a lot of work ahead
            of us. We know and understand this which is why everyday, we work
            closely with our customers, identifying the different ways we can
            support them and move their business further.
          </p>
          <p>
            Our customers employ tens of people, therefore increasing the labour
            force. They come in various types and sizes with varying needs. Some
            are in retail, while others are in services and as we talk to many
            of them, we see how our work is very important and we take that very
            seriously.
          </p>
          <p>
            We believe that small businesses matter and the bigger ones need
            better services. Support in form of capital and other growth
            services should be easily accessible to them. Financial services
            must be honest. People must be treated well. And because we believe
            so much in all these, we get up everyday to ensure that our work
            promotes our mission.
          </p>
          <p>
            Our promise to you is simple. To continuously elevate what it means
            to deliver great services that can make entrepreneurship more
            permission-less and successful.
          </p>
          <p>
            We are building at the union of business, financial services and
            technology. While it can be challenging, it is also exciting. If you
            wish to learn more about our work, you can always contact us. If you
            share in our mission and like us, you are inspired to help develop
            the SME economy in the emerging markets -it is important work that
            must be done, please look out for our career opportunities. Come do
            your best work with us.
          </p>
        </section>
        <ExternalResearch />
        <section className={styles.WhyBrass_container_fullWidthImg}>
          <StaticImage
            src="../../assets/media/images/customers/nifries/group-photo.png"
            alt="group"
            as="span"
          />
        </section>

        <section className={styles.WhyBrass_container}>
          <p>
            We care very deeply about our work and the kind of impacts we can
            make with it. We have a very few sets of beliefs and strongly-held
            ideas we're betting on, about the future of enterprise and commerce
            in emerging economies.
          </p>
          <p>
            We are, therefore, building commerce-enabling financial products,
            from the ground up, stacks of new services, honest and very
            critical, to help local businesses become more successful.
          </p>
          <p>
            We serve thousands of businesses today but we are just getting
            started. They share their stories on how much Brass has done. We
            will continue to work to make entrepreneurship more permissionless
            and successful on the continent and everywhere else it is most
            needed.
          </p>
        </section>

        {/* <HighlightCard
        size="large"
        border
        items={WHY_BRASS_DATA.LEARN_MORE_OPTIONS}
      /> */}
        <GetStarted theme="light" />
      </div>
    </>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
      }
    }
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "why-brass" } } }
    ) {
      edges {
        node {
          frontmatter {
            customerData {
              caption
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default WhyBrass;
