import React from "react";
import cx from "classnames";
import { uniqueId } from "lodash";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./CustomersGrid.module.scss";

interface CustomersGridProps {
  items: { img: any; caption: string }[];
}

const CustomersGrid: React.FC<CustomersGridProps> = ({ items }) => {
  const [activeImage, setActiveImage] = React.useState(0);

  return (
    <section className={styles.CustomersGrid}>
      <div className={styles.CustomersGrid_laptop}>
        {items.map(({ img, caption }) => {
          const image = getImage(img.childImageSharp.gatsbyImageData);
          return (
            <div key={uniqueId()} className={styles.CustomersGrid_item}>
              {image && <GatsbyImage image={image} alt={caption} as="span" />}
              <p>{caption}</p>
            </div>
          );
        })}
      </div>
      <div className={styles.CustomersGrid_mobile}>
        {items.map(({ img, caption }, index) => {
          const image = getImage(img.childImageSharp.gatsbyImageData);
          return (
            <div
              key={index}
              className={cx(
                styles.CustomersGrid_item,
                styles.CustomersGrid_mobile_item,
                {
                  [styles.CustomersGrid_mobile_item_active]:
                    activeImage === index,
                }
              )}
            >
              {image && <GatsbyImage image={image} alt={caption} as="span" />}
              <p>{caption}</p>
            </div>
          );
        })}
        <div className={styles.CustomersGrid_selectors}>
          {items.map((item, index) => (
            <div
              key={index}
              className={cx(styles.CustomersGrid_selector, {
                [styles.CustomersGrid_selector_selected]: activeImage === index,
              })}
              onClick={() => setActiveImage(index)}
            ></div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CustomersGrid;
