// extracted by mini-css-extract-plugin
export var CustomersGrid = "CustomersGrid-module--CustomersGrid--BrMBL";
export var CustomersGrid_item = "CustomersGrid-module--CustomersGrid_item--Hat1H";
export var CustomersGrid_laptop = "CustomersGrid-module--CustomersGrid_laptop--4MbqE";
export var CustomersGrid_mobile = "CustomersGrid-module--CustomersGrid_mobile--I60cH";
export var CustomersGrid_mobile_item = "CustomersGrid-module--CustomersGrid_mobile_item--sSe5S";
export var CustomersGrid_mobile_item_active = "CustomersGrid-module--CustomersGrid_mobile_item_active--v2KZi";
export var CustomersGrid_selector = "CustomersGrid-module--CustomersGrid_selector--bZKqp";
export var CustomersGrid_selector_selected = "CustomersGrid-module--CustomersGrid_selector_selected--YLBqd";
export var CustomersGrid_selectors = "CustomersGrid-module--CustomersGrid_selectors--1CHJ7";
export var container = "CustomersGrid-module--container--ftTMR";
export var gatsbyImageWrapperConstrained = "CustomersGrid-module--gatsby-image-wrapper-constrained--0LUfY";
export var gridSpan1010 = "CustomersGrid-module--grid-span-10-10--IeeJZ";
export var gridSpan1011 = "CustomersGrid-module--grid-span-10-11--QFUPI";
export var gridSpan102 = "CustomersGrid-module--grid-span-10-2--wvVBX";
export var gridSpan103 = "CustomersGrid-module--grid-span-10-3--3zKHs";
export var gridSpan104 = "CustomersGrid-module--grid-span-10-4--qtzoS";
export var gridSpan105 = "CustomersGrid-module--grid-span-10-5--+WNR9";
export var gridSpan106 = "CustomersGrid-module--grid-span-10-6--rbmED";
export var gridSpan107 = "CustomersGrid-module--grid-span-10-7--ZtRcy";
export var gridSpan108 = "CustomersGrid-module--grid-span-10-8--9hi+L";
export var gridSpan109 = "CustomersGrid-module--grid-span-10-9--A62tW";
export var gridSpan110 = "CustomersGrid-module--grid-span-1-10--w9Hv7";
export var gridSpan111 = "CustomersGrid-module--grid-span-1-11--hcoIg";
export var gridSpan1110 = "CustomersGrid-module--grid-span-11-10--wi6gS";
export var gridSpan1111 = "CustomersGrid-module--grid-span-11-11--wgo9I";
export var gridSpan112 = "CustomersGrid-module--grid-span-11-2--4muVy";
export var gridSpan113 = "CustomersGrid-module--grid-span-11-3--13u4H";
export var gridSpan114 = "CustomersGrid-module--grid-span-11-4--XyCLx";
export var gridSpan115 = "CustomersGrid-module--grid-span-11-5--87iM2";
export var gridSpan116 = "CustomersGrid-module--grid-span-11-6--4yhzv";
export var gridSpan117 = "CustomersGrid-module--grid-span-11-7--lGJEc";
export var gridSpan118 = "CustomersGrid-module--grid-span-11-8--KEozc";
export var gridSpan119 = "CustomersGrid-module--grid-span-11-9--RaZ4O";
export var gridSpan12 = "CustomersGrid-module--grid-span-1-2--AizSW";
export var gridSpan1210 = "CustomersGrid-module--grid-span-12-10---6NWz";
export var gridSpan1211 = "CustomersGrid-module--grid-span-12-11--CJwzB";
export var gridSpan122 = "CustomersGrid-module--grid-span-12-2--Bc2tX";
export var gridSpan123 = "CustomersGrid-module--grid-span-12-3--OF2B1";
export var gridSpan124 = "CustomersGrid-module--grid-span-12-4--N8x3X";
export var gridSpan125 = "CustomersGrid-module--grid-span-12-5--mTq+P";
export var gridSpan126 = "CustomersGrid-module--grid-span-12-6--J1PBe";
export var gridSpan127 = "CustomersGrid-module--grid-span-12-7--oUOE9";
export var gridSpan128 = "CustomersGrid-module--grid-span-12-8--6cqnt";
export var gridSpan129 = "CustomersGrid-module--grid-span-12-9--rM6bt";
export var gridSpan13 = "CustomersGrid-module--grid-span-1-3--RQhhh";
export var gridSpan14 = "CustomersGrid-module--grid-span-1-4--yH9Z+";
export var gridSpan15 = "CustomersGrid-module--grid-span-1-5--J23qt";
export var gridSpan16 = "CustomersGrid-module--grid-span-1-6--OJ7N7";
export var gridSpan17 = "CustomersGrid-module--grid-span-1-7--DxS23";
export var gridSpan18 = "CustomersGrid-module--grid-span-1-8--1TWBi";
export var gridSpan19 = "CustomersGrid-module--grid-span-1-9--guCeU";
export var gridSpan210 = "CustomersGrid-module--grid-span-2-10--XgYg7";
export var gridSpan211 = "CustomersGrid-module--grid-span-2-11--4cYwX";
export var gridSpan22 = "CustomersGrid-module--grid-span-2-2--n1ice";
export var gridSpan23 = "CustomersGrid-module--grid-span-2-3--7--MK";
export var gridSpan24 = "CustomersGrid-module--grid-span-2-4--Lzs2T";
export var gridSpan25 = "CustomersGrid-module--grid-span-2-5--4wttK";
export var gridSpan26 = "CustomersGrid-module--grid-span-2-6--xQ5eK";
export var gridSpan27 = "CustomersGrid-module--grid-span-2-7--RJNC8";
export var gridSpan28 = "CustomersGrid-module--grid-span-2-8--8ws75";
export var gridSpan29 = "CustomersGrid-module--grid-span-2-9--rZ3Yg";
export var gridSpan310 = "CustomersGrid-module--grid-span-3-10--6q6Oa";
export var gridSpan311 = "CustomersGrid-module--grid-span-3-11--ivoFQ";
export var gridSpan32 = "CustomersGrid-module--grid-span-3-2--lrz7K";
export var gridSpan33 = "CustomersGrid-module--grid-span-3-3--IfP4q";
export var gridSpan34 = "CustomersGrid-module--grid-span-3-4---wqbf";
export var gridSpan35 = "CustomersGrid-module--grid-span-3-5--shYaJ";
export var gridSpan36 = "CustomersGrid-module--grid-span-3-6--jTmuK";
export var gridSpan37 = "CustomersGrid-module--grid-span-3-7--pS+kD";
export var gridSpan38 = "CustomersGrid-module--grid-span-3-8--Vg4-W";
export var gridSpan39 = "CustomersGrid-module--grid-span-3-9--EqXXJ";
export var gridSpan410 = "CustomersGrid-module--grid-span-4-10--99FQB";
export var gridSpan411 = "CustomersGrid-module--grid-span-4-11--iIkWy";
export var gridSpan42 = "CustomersGrid-module--grid-span-4-2--RsWiO";
export var gridSpan43 = "CustomersGrid-module--grid-span-4-3--CiQwh";
export var gridSpan44 = "CustomersGrid-module--grid-span-4-4--F+yrI";
export var gridSpan45 = "CustomersGrid-module--grid-span-4-5--tP8yz";
export var gridSpan46 = "CustomersGrid-module--grid-span-4-6--tIegG";
export var gridSpan47 = "CustomersGrid-module--grid-span-4-7--RuzD2";
export var gridSpan48 = "CustomersGrid-module--grid-span-4-8--2bBbz";
export var gridSpan49 = "CustomersGrid-module--grid-span-4-9--+NOau";
export var gridSpan510 = "CustomersGrid-module--grid-span-5-10--SQoI1";
export var gridSpan511 = "CustomersGrid-module--grid-span-5-11--s6mL1";
export var gridSpan52 = "CustomersGrid-module--grid-span-5-2--jtm1W";
export var gridSpan53 = "CustomersGrid-module--grid-span-5-3--fkbA7";
export var gridSpan54 = "CustomersGrid-module--grid-span-5-4--WVqKG";
export var gridSpan55 = "CustomersGrid-module--grid-span-5-5--0iyZ8";
export var gridSpan56 = "CustomersGrid-module--grid-span-5-6--tm8f9";
export var gridSpan57 = "CustomersGrid-module--grid-span-5-7--Cd819";
export var gridSpan58 = "CustomersGrid-module--grid-span-5-8--Srjdi";
export var gridSpan59 = "CustomersGrid-module--grid-span-5-9--OAehf";
export var gridSpan610 = "CustomersGrid-module--grid-span-6-10--R1aA2";
export var gridSpan611 = "CustomersGrid-module--grid-span-6-11--z6Dti";
export var gridSpan62 = "CustomersGrid-module--grid-span-6-2--yPZES";
export var gridSpan63 = "CustomersGrid-module--grid-span-6-3--AbU6Z";
export var gridSpan64 = "CustomersGrid-module--grid-span-6-4--HdH+3";
export var gridSpan65 = "CustomersGrid-module--grid-span-6-5--Ks15d";
export var gridSpan66 = "CustomersGrid-module--grid-span-6-6--I44xn";
export var gridSpan67 = "CustomersGrid-module--grid-span-6-7--ocrJe";
export var gridSpan68 = "CustomersGrid-module--grid-span-6-8--PCy0g";
export var gridSpan69 = "CustomersGrid-module--grid-span-6-9--zAjxn";
export var gridSpan710 = "CustomersGrid-module--grid-span-7-10--1wBde";
export var gridSpan711 = "CustomersGrid-module--grid-span-7-11--+rGYv";
export var gridSpan72 = "CustomersGrid-module--grid-span-7-2--T9PWg";
export var gridSpan73 = "CustomersGrid-module--grid-span-7-3--x2eUb";
export var gridSpan74 = "CustomersGrid-module--grid-span-7-4--xKFLQ";
export var gridSpan75 = "CustomersGrid-module--grid-span-7-5--S+MvJ";
export var gridSpan76 = "CustomersGrid-module--grid-span-7-6--gm1LK";
export var gridSpan77 = "CustomersGrid-module--grid-span-7-7--FiflB";
export var gridSpan78 = "CustomersGrid-module--grid-span-7-8--o+mF6";
export var gridSpan79 = "CustomersGrid-module--grid-span-7-9--QHKVK";
export var gridSpan810 = "CustomersGrid-module--grid-span-8-10--6QSAG";
export var gridSpan811 = "CustomersGrid-module--grid-span-8-11---uxic";
export var gridSpan82 = "CustomersGrid-module--grid-span-8-2--LfF5x";
export var gridSpan83 = "CustomersGrid-module--grid-span-8-3--Hi7xg";
export var gridSpan84 = "CustomersGrid-module--grid-span-8-4--RMvTG";
export var gridSpan85 = "CustomersGrid-module--grid-span-8-5--mnL2Y";
export var gridSpan86 = "CustomersGrid-module--grid-span-8-6--TsKbr";
export var gridSpan87 = "CustomersGrid-module--grid-span-8-7--1aeGv";
export var gridSpan88 = "CustomersGrid-module--grid-span-8-8--U8wPr";
export var gridSpan89 = "CustomersGrid-module--grid-span-8-9--LPpED";
export var gridSpan910 = "CustomersGrid-module--grid-span-9-10--rncAg";
export var gridSpan911 = "CustomersGrid-module--grid-span-9-11--lIyJY";
export var gridSpan92 = "CustomersGrid-module--grid-span-9-2--EzsS7";
export var gridSpan93 = "CustomersGrid-module--grid-span-9-3--SAlDQ";
export var gridSpan94 = "CustomersGrid-module--grid-span-9-4--KSUis";
export var gridSpan95 = "CustomersGrid-module--grid-span-9-5--wOxyS";
export var gridSpan96 = "CustomersGrid-module--grid-span-9-6--LOqD6";
export var gridSpan97 = "CustomersGrid-module--grid-span-9-7--PW1rq";
export var gridSpan98 = "CustomersGrid-module--grid-span-9-8--K4Ywu";
export var gridSpan99 = "CustomersGrid-module--grid-span-9-9--yTyel";
export var textBreak = "CustomersGrid-module--text-break--90Ao+";